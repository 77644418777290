/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState } from 'react';
import {
    bool, object, string, arrayOf, shape, array,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CallIcon from '@material-ui/icons/Call';
import brandThemes from '../../AppShell/brandtheme';
import ShopTheSiteBanner from '../../../pages/Account/components/GiftList/ShopTheSiteBanner';
import MobileFooterBlock from './MobileFooterBlock/MobileFooterBlock';
import LegalLinks from './LegalLinks';
import LegalRecaptchaLinks from './LegalRecaptchaLinks';

const useStyles = makeStyles((theme) => ({
    mobileFooter: {
        display: 'block',
        position: 'relative',
        paddingBottom: 48,
    },
    textCenter: {
        textAlign: 'center',
    },
    foodMobileFooter: {
        marginTop: '50px',
        display: 'block',
        paddingBottom: 48,
        position: 'relative',
        '& a': {
            textDecoration: 'none',
            '&.call-info': {
                margin: '-28px 0 0 0',
                display: 'inline-block',
                '& svg': {
                    color: theme?.palette?.common?.white,
                    margin: '0 auto',
                    display: 'block',
                    padding: '10px 8px',
                    fontSize: '55px',
                    borderRadius: '50%',
                    backgroundColor: theme?.palette?.secondary?.main,
                },
                '& div': {
                    color: theme?.palette?.primary?.main,
                    padding: '5px 8px',
                    fontSize: '16px',
                },
            },
        },
    },
}));
const MobileGraphqlFooter = ({
    mobileFooterData, brand, self, titles, close, legalData, isRecaptchaCheckoutEnabled,
}) => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);
    const [title, setTitles] = useState('');

    const setTitle = () => {
        if (self) {
            setTitles(titles);
            setOpenDialog(true);
        } else {
            setTitles(titles);
            setOpenDialog(true);
        }
    };

    const setDialog = () => {
        if (self) {
            setOpenDialog(close);
        } else {
            setOpenDialog(false);
        }
    };

    const mobileFooter = () => {
        const styleOverrides = {};
        const presentationFamily = brandThemes[brand?.code]?.presentation_family;
        const foodPresentation = (presentationFamily === 'food');
        if (mobileFooterData[0]?.footer_blocks?.length) {
            const blocks = mobileFooterData[0]?.footer_blocks;
            let popUpData = [];
            let footerData = [];
            let bottomLinks = [];
            blocks.forEach((footerBlockItem) => {
                bottomLinks = footerBlockItem?.footer_bottom_links?.link_group || bottomLinks;
                footerData = footerBlockItem?.menu_group_links?.menu_group_link_ref || footerData;
            });
            if (foodPresentation && footerData && footerData.length > 0) {
                styleOverrides.backgroundColor = footerData[0].background_color?.color;
            }
            if (bottomLinks && bottomLinks.length > 0) {
                popUpData = bottomLinks.filter((bottomLinkItem) => bottomLinkItem.popup === true);
            }
            const chatBlock = blocks?.find((block) => block?.chat) || {};
            return (
                <div className={(foodPresentation) ? classes.foodMobileFooter : classes.mobileFooter} style={styleOverrides}>
                    {(foodPresentation)
                    && (
                        <Grid container>
                            <Grid item xs={12} className={classes.textCenter}>
                                <Typography component="a" className="call-info" href="tel:1-877-455-7700">
                                    <CallIcon />
                                    <Typography component="div">
                                        Call
                                    </Typography>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {blocks.map((block, index) => {
                        const keyItem = `mobileFooterBlock${index}`;
                        return (
                            <MobileFooterBlock
                                block={block}
                                self={this}
                                setDialog={setDialog}
                                setPopupTitle={setTitle}
                                key={keyItem}
                                bottomLinks={bottomLinks}
                                popUpData={popUpData}
                                openDialog={openDialog}
                                title={title}
                                presentationFamily={presentationFamily}
                                chatBlock={chatBlock}
                            />
                        );
                    })}
                    <LegalLinks data={legalData} />
                    <LegalRecaptchaLinks isRecaptchaCheckoutEnabled={isRecaptchaCheckoutEnabled} legalData={legalData} brand={brand} />
                    <ShopTheSiteBanner />
                </div>
            );
        }
        return (<></>);
    };

    return mobileFooter();
};
MobileGraphqlFooter.defaultProps = {
    mobileFooterData: [],
};

MobileGraphqlFooter.propTypes = {
    mobileFooterData: arrayOf(
        shape({
            footer_blocks: array.isRequired,
            locale: string.isRequired,
            title: string.isRequired,
            uid: string.isRequired,
        }).isRequired,
    ),
    brand: object.isRequired,
    legalData: array.isRequired,
    isRecaptchaCheckoutEnabled: bool.isRequired,
};

export default MobileGraphqlFooter;
